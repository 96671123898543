/*===========================
     14.SERVICE PAGE CSS 
===========================*/
.how-we-work {
  @media #{$xs} {
    margin-top: 80px;
  }

  .title.black {
    margin-bottom: -80px;

    @media #{$lg} {
      margin-bottom: -50px;
    }

    @media #{$xs} {
      margin-bottom: 0;
    }

    h2 {
      margin: 0;
    }
  }
}

.swiper.work-process {
  padding-top: 80px;

  @media #{$xs} {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media #{$lg} {
    padding-top: 110px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    height: 40px !important;
    width: 40px !important;
    background: #2a2a2a;
    border-radius: 50%;
    color: #fff !important;
    top: 22px;
    right: 0% !important;
    @include transition(0.5s);

    @media #{$xs} {
      display: none;
    }

    &:hover {
      background-color: $theme-color;
    }
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 14px !important;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: auto;
    right: 5% !important;

    @media #{$md} {
      right: 12% !important;
    }

    @media #{$xs} {
      right: 22% !important;
    }
  }
}

.work-process-wrapper {
  position: relative;

  img {
    width: 100%;
    max-height: 650px;
    object-fit: cover;
  }

  .work-process-inner {
    position: absolute;
    min-width: 350px;
    left: 0;
    top: 0;
    background: #17161a;
    text-align: center;
    padding: 40px 60px;

    @media #{$xs} {
      padding: 30px 60px;
    }

    b {
      font-weight: bold;
      font-size: 65px;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      color: #ffffff;
      opacity: 0.1;
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      line-height: 1;
    }

    h4 {
      font-weight: 600;
      font-size: 20px;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      color: #ffffff;
      margin: 0;
    }
  }
}

.pricing-plan {
  @media #{$md,$xs} {
    .or2 {
      order: 2;
    }

    .or1 {
      order: 1;
    }
  }

  @media #{$xs} {
    margin-top: 0;
    margin-bottom: 50px;
  }
}

#pills-tab {
  margin: 0 !important;

  @media #{$xs} {
    justify-content: center;
  }
}

#pills-tabContent {
  margin-top: 40px;
}

.nav-pills {
  padding-top: 15px;
  .nav-link {
    background: #191a1c;
    box-shadow: inset 0px 0px 10px rgba(6, 6, 6, 0.15);
    border-radius: 50px 0px 0px 50px;
    min-width: 170px;
    padding: 15px 30px;
    font-weight: 600;
    font-size: 17px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #ffffff;
    margin: 15px 0;

    @media #{$xs} {
      min-width: 140px;
      font-size: 14px;
      margin-right: 0;
      margin: 0 5px;
    }

    @media #{$md} {
      margin-bottom: 30px;
      min-width: 70px;
    }

    @media #{$sm} {
      margin-bottom: 20px;
      min-width: 30px;
    }
    &.before{
      background-color: #000000;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &.active,
    &:hover {
      background: linear-gradient(89.91deg, #d90a2c 0.08%, #730000 99.92%);
      box-shadow: inset 0px 0px 10px rgba(6, 6, 6, 0.15);
      transition: all 0.5s ease-out 0s;
    }
  }
}

.sidebar_btn{
    border: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    padding-top: 15px;
    min-width: 170px;
    border-radius: 50px 0px 0px 50px;
    padding: 15px 30px;
    font-weight: 600;
    font-size: 17px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #fff;
    background: linear-gradient(90deg, #d90a2c 1.05%, #730000 100%);
    z-index: 1;
    @include transition(0.5s);
    margin: 15px 0;

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 0%;
      top: 50%;
      left: 50%;
      background-color: #000000;
      transform: translate(-50%, -50%) rotate(-45deg);
      z-index: -1;
      @include transition(0.5s);
    }
    &.active,
    &:hover {
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);

      &:before {
        height: 380%;
      }
    }
  
}


.single-price-box {
  position: relative;
  background: #111111;
  box-shadow: inset 0px 0px 10px rgba(6, 6, 6, 0.15);
  border-radius: 10px;
  padding: 40px 55px;
  text-align: center;
  z-index: 1;
  height: 100%;

  @media #{$md,$xs} {
    padding: 40px 30px;
    margin-bottom: 30px;
  }

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      152.97deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border-radius: 10px;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    @include transition(0.5s);
  }

  &:hover:before {
    visibility: visible;
    opacity: 1;
  }

  &:hover .pay-btn button {
    background: transparent;
    background-color: #0a0a0a;
  }

  h3 {
    position: relative;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #ffffff;

    @media #{$lg} {
      font-size: 24px;
    }

    &:before {
      position: absolute;
      content: '';
      background: linear-gradient(90deg, #d90a2c 1.05%, #730000 100%);
      width: 150px;
      height: 2px;
      left: 50%;
      bottom: -10px;
      transform: translateX(-50%);
    }
  }

  span {
    font-weight: normal;
    font-size: 15px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #ffffff;
  }

  h2 {
    font-weight: 700;
    font-size: 18px;
    text-align: left;
    color: #e4e4e4;
    margin-top: 40px;
    margin-bottom: 0;
    width: 260px;

    @media #{$lg} {
      font-size: 24px;
    }

    sub {
      font-size: 16px;
      font-weight: 400;
      color: #d90a2c;
      text-transform: capitalize;
    }
  }

  .feature-list {
    text-align: left;
    margin-top: 35px;
    margin-bottom: 40px;

    li {
      font-size: 16px;
      color: #ffffff;
      padding: 10px 0;

      i {
        margin-right: 10px;
      }
    }
  }

  .pay-btn {
    button {
      border: none;
      font-weight: 700;
      font-size: 17px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #ffffff;
      display: inline-block;
      background: linear-gradient(90deg, #d90a2c 1.05%, #730000 100%);
      border-radius: 30px;
      min-width: 200px;
      padding: 15px 30px;
      @include transition(0.5s);
    }
  }
}
.question-div{
  margin-bottom: 30px;
}
.price-input{
  height: 43px;
    width: 100%;
    border-radius: 7px;
    border: none;
    align-items: center;
    padding: 6px 12px;
    cursor: default
}
.select-heading{
  color: white;
    width: 300px;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
}