/*===========================
     11.LETS TALK CSS 
===========================*/
.lets-talk {
    background-image: url(../../../public/images/letstalk-bg.png);
    background-size: cover;
    background-position: center center;
    position: relative;

    @media #{$xs} {
        padding: 80px 0;
    }

    &:before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        background: #111215;
        z-index: -1;
    }

    .title.special {

        @media #{$xs} {
            text-align: center;
        }

        h1 {
            margin-bottom: 0;

            @media #{$md} {
                font-size: 50px;
            }

            b {
                @media #{$md} {
                    font-size: 55px;
                }
            }
        }
    }

}

.getin-touch {
    @media #{$xs} {
        text-align: center;
        margin-top: 20px;
    }
}