// Colors ---------------
$white: #ffffff;
$black: #2E2E2E;
$gray:#f7f7fd;


// Theme Color
$theme-color: #D90A2C;

// Responsive Variables
$xl-desktop: 'only screen and (min-width: 1921px)';
$desktop: 'only screen and (min-width: 1400px) and (max-width: 1650px)';
$laptop: 'only screen and (min-width: 1200px) and (max-width: 1399px)';
$lg: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md: 'only screen and (min-width: 768px) and (max-width: 991px)';
$xs: '(max-width: 767px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';

$responsive-mobile-menu: 'only screen and (max-width: 1199px)';
