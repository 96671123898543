/*===========================
     18.CONTACT PAGE CSS 
===========================*/
.contact-area {

    @media #{$xs} {
        margin: 80px 0;
    }
}

.office-info {
    text-align: center;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 5px;
    min-height: 250px;
    padding: 30px;
    @include transition(.5s);

    @media #{$md,$xs} {
        margin-bottom: 30px;
    }

    &:hover {
        border-radius: 50px 5px 5px 5px;
    }

    &:hover .icon {
        background-color: #D90A2C;
    }

    &:hover .icon i {
        color: #fff;
    }

    .icon {
        width: 80px;
        height: 80px;
        background-color: #D90A2C30;
        border-radius: 50%;
        text-align: center;
        line-height: 80px;
        margin: 0 auto;
        margin-bottom: 15px;
        @include transition(.5s);

        i {
            color: #D90A2C;
            font-size: 45px;
            @include transition(.5s);
        }
    }

    h4 {
        font-weight: 600;
        font-size: 25px;
        margin-bottom: 10px;
        line-height: 30px;
        text-align: center;
        text-transform: uppercase;
        color: #D90A2C;
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: #fff;
        margin: 0;

        @media #{$lg} {
            font-size: 15px;
        }

        @media #{$xs} {
            margin-bottom: 0;
        }

    }

    a {
        font-weight: 500;
        font-size: 16px;
        display: block;
        text-align: center;
        color: #fff;
        padding-bottom: 8px;
        @include transition(.5s);

        &:last-child {
            padding-bottom: 0;
        }

        &:hover {
            color: $theme-color;
        }
    }
}

.meeting-btn{
    background-color: transparent;
    border: none;
    font-weight: 500;
        font-size: 16px;
        text-align: center;
        color: #fff;
        padding-bottom: 8px;
        @include transition(.5s);

        &:last-child {
            padding-bottom: 0;
        }

        &:hover {
            color: $theme-color;
        }
}

.contact-information {
    position: relative;
    margin-top: 70px;
    overflow: hidden;

    @media #{$xs} {
        margin-top: 80px;
    }
}

.contact-form {
    @media #{$xs} {
        text-align: center;
    }

    h3 {
        font-weight: bold;
        font-size: 30px;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 40px;

        @media #{$xs} {
            font-size: 24px;
        }
    }

    form {

        input,
        textarea {
            background: #FFFFFF;
    border: none;
    box-sizing: border-box;
    border-radius: 0.75rem;
    width: 100%;
    //margin-bottom: 1rem;
    height: 60px;
    padding: 8px 12px;
        }

        textarea {
            height: 150px;
            padding: 25px;
            resize: none;
        }

        input[type="submit"] {
            max-width: 180px;
            background-color: $theme-color;
            font-weight: 600;
            font-size: 16px;
            line-height: 25px;
            text-align: center;
            color: #FFFFFF;
            border: none;
            border: 2px solid transparent;
            @include transition(.5s);

            &:hover {
                color: #272727;
                background-color: transparent;
                border: 2px solid $theme-color;
            }
        }
    }
}

.form-container{
    background: linear-gradient(90deg, #d90a2c 1.05%, #730000 100%);
    border-radius: 25px;
    padding: 25px 25px;
}

.google-map {
    position: absolute;
    right: 0;
    top: 0;

    @media #{$md,$xs} {
        position: static;
    }

    iframe {
        width: 850px;
        height: 570px;

        @media #{$desktop} {
            width: 650px;
        }

        @media #{$laptop} {
            width: 550px;
        }

        @media #{$lg} {
            width: 450px;
        }

        @media #{$md,$xs} {
            width: 100%;
        }
    }
}
