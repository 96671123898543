:root {
  --light-dark: #383b3f;
  --dark-dark: rgb(25, 26, 28);
  --input-dark: rgb(33, 33, 33);
  --black: #000;
  --text-white: #fff;
  --text-black: #000;
  --title: 20px;
  --heading: 32px;
  --main-title: 36px;
  
  --desc: 18px;
  }
  
  .dashboardMain__boxs {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1.25px solid;
  -webkit-box-shadow: 0px 8px 16px -4px rgba(54, 56, 58, 0.08);
  box-shadow: 0px 8px 16px -4px rgba(22, 34, 51, 0.08);
  border-radius: 8px;
  padding: 20px 17px;
  margin-bottom: 28px;
  overflow-x: hidden;
  }
  
  .Repbtn {
  background: linear-gradient(90deg, #d90a2c 1.05%, #730000 100%);
  border-radius: 30px;
  padding: 2px 4px;
  width: 100px;
  height: 32px;
  color: #ffff;
  }
  
  .Repbatn {
  background-color: rgba(67, 201, 169, 0.1);
  border-radius: 6px;
  border: 1px solid #27c19c;
  padding: 2px 4px;
  width: 100px;
  height: 32px;
  color: #27c19c;
  }
  
  .category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -6px;
  overflow-x: auto;
  background-color: #fafbfc;
  }
  
  .categoryItem {
  margin: 6px;
  background: #fff;
  border: 1px solid #e6e7eb;
  -webkit-box-shadow: 0px 10px 20px rgba(9, 0, 65, 0.05);
  box-shadow: 0px 10px 20px rgba(9, 0, 65, 0.05);
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #737379;
  padding: 12px 22px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  }
  
  .categoryItem.active {
  color: #fff;
  background: #27c19c;
  }
  
  .categoryItem.active svg {
  color: #fff;
  }
  
  .categoryItem svg {
  color: #ff9b63;
  width: 24px;
  height: 24px;
  margin-right: 7px;
  }
  
  .Dashbord_dots {
  width: auto;
  border-radius: 5px;
  background-color: #333436;
  border: none;
  color: white;
  padding: 0px 7px;
  margin: 0px 5px;
  
  @media only screen and (max-width: 1199px) {
  header .main-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
  padding: 10px 15px !important;
  z-index: 99999;
  height: 100%;
  overflow: auto;
  background: #242424;
  -webkit-transform: translateX(-260px);
  transform: translateX(-260px);
  -webkit-transition: -webkit-transform 0.3s ease-in;
  transition: -webkit-transform 0.3s ease-in;
  transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
  }
  
  header .main-nav .mobile-menu-logo {
  text-align: left;
  padding-top: 20px;
  display: block;
  border-bottom: 1px solid #d90a2c;
  padding-bottom: 8px;
  }
  
  header .main-nav ul {
  float: none;
  text-align: left;
  padding: 25px 10px 25px 0;
  }
  
  header .main-nav ul li {
  display: block;
  position: relative;
  padding: 0 5px;
  }
  
  header .main-nav ul li button,
  header .main-nav ul li a {
  padding: 10px 0;
  display: block;
  font-weight: 300;
  }
  
  header .main-nav ul li ul.sub-menu {
  position: static;
  min-width: 200px;
  background: 0 0;
  border: none;
  opacity: 1;
  visibility: visible;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transform: none;
  transform: none;
  -webkit-transition: none;
  transition: none;
  display: none;
  margin-top: 0 !important;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  }
  
  header .main-nav.slidenav {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  }
  
  header .main-nav ul li .bi {
  display: block;
  top: 8px;
  font-size: 16px;
  }
  
  .mobile-menu {
  position: relative;
  top: 2px;
  padding: 0 5px;
  border-radius: 50%;
  display: inline-block;
  }
  
  .cross-btn {
  display: inline-block !important;
  position: relative;
  width: 30px !important;
  height: 22px !important;
  cursor: pointer;
  border: 3px solid transparent !important;
  }
  
  .cross-btn span {
  width: 100%;
  height: 2px;
  background: linear-gradient(233.77deg,
  rgba(115, 0, 0, 0.8) 0.94%,
  rgba(217, 10, 44, 0.8) 99.09%) !important;
  display: block;
  position: absolute;
  right: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  }
  
  .cross-btn .cross-top {
  top: 0;
  }
  
  .cross-btn .cross-middle {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  }
  
  .cross-btn .cross-bottom {
  bottom: 0;
  width: 100%;
  }
  
  .cross-btn.h-active span.cross-top {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 50%;
  margin-top: -1px;
  }
  
  .cross-btn.h-active span.cross-middle {
  -webkit-transform: translateX(-30px);
  transform: translateX(-30px);
  opacity: 0;
  }
  
  .cross-btn.h-active span.cross-bottom {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  bottom: 50%;
  margin-bottom: -1px;
  }
  }
  }
  
  .user_setting_dropdown {
  a.img-tag {
  padding: 20px 0px 0px 0px !important;
  }
  
  img {
  height: 50px;
  width: 50px;
  object-fit: contain;
  border-radius: 50%;
  }
  }
  
  .setting__wrapper {
  margin-top: 200px;
  padding-bottom: 50px;
  
  p.description {
  margin-bottom: 30px;
  }
  
  .cmn-btn {
  margin: 35px 0px;
  }
  
  .id_icon {
  height: 35px;
  width: 35px;
  object-fit: contain;
  margin-top: 10px;
  }
  
  .card {
  border-radius: 40px;
  padding: 35px 35px !important;
  background-color: var(--input-dark) !important;
  }


  .left {
  // background: #730000;
  margin-top: 50px;
  }
  
  .upload-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #676767eb;
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  
  .detail-card {
  padding: 30px;
  display: flex;
  padding: 70px 35px 35px !important;
  
  .profile-img {
  position: absolute;
  height: 100px;
  width: 100px;
  top: 0px;
  
  img.profile {
  position: absolute;
  left: 0;
  top: -50px;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  object-fit: cover;
  }
  }
  }
  
  .functional {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 35px !important;
  
  .list-bordered {
  width: 100%;
  
  li {
  position: relative;
  overflow: auto;
  display: flex;
  padding: 15px 0px;
  
  .arrow {
  position: absolute;
  right: 0;
  height: 25px;
  width: 25px;
  object-fit: contain;
  margin-top: 21px;
  }
  
  a {
  font-size: var(--title);
  float: left;
  margin-left: 25px;
  padding: 10px 0px;
  }
  
  &:last-child {
  &::after {
  display: none;
  }
  }
  
  &::after {
  content: '';
  position: absolute;
  border-bottom: 1px solid #8c8c8c;
  width: 100%;
  height: 5px;
  bottom: 0;
  left: 0;
  // right: 0;
  margin-top: 20px;
  }
  }
  }
  }
  
  .right {
  margin-top: 50px;
  padding: 0px 30px 0px 30px;
  
  label {
  color: #fff;
  margin-left: 10px;
  font-weight: bold;
  
  }
  
  .card {
  p {
  color: #fff;
  }
  
  .title {
  margin-bottom: 0;
  }
  }
  }
  }
  
  // .table-card {
  // padding: 20px 25px;
  // border-radius: 20px;
  // }
  
  .career__wrapper {
  margin-top: 10px;
  
  .main-heading {
  margin: 20px 0px;
  }
  
  p.desc {
  color: var(--text-white);
  }
  
  .section-heading {
  p.title {
  font-weight: 400;
  }
  
  .description {
  margin-top: 20px;
  }
  }
  
  .career-form {
  margin-top: 30px;
  margin-bottom: 50px;
  
  .section-heading {
  margin-bottom: 30px;
  }
  
  label.form-label {
  color: #fff;
  }
  
  .form-card {
  padding: 30px 50px;
  border-radius: 30px;
  
  input.bd-input {
  background-color: #383b3f;
  width: 400px;
  align-self: start;
  margin-top: 15px;
  }
  
  input.bd-input:focus {
  background-color: #383b3f;
  }
  
  p.form-label {
  color: var(--text-white);
  font-weight: 500;
  margin-bottom: 0;
  margin-left: 20px;
  }
  
  .resume-btn {
  div {
  border-radius: 25px;
  padding: 5px 20px;
  margin-top: 15px;
  }
  
  img.clip {
  height: 20px;
  width: 20px;
  object-fit: contain;
  }
  }
  
  .resume-upload {
  justify-self: center;
  
  a {
  font-size: var(--title);
  }
  }
  }
  }
  
  .resume-btn-row {
  margin-left: 100px;
  }
  
  .benefits {
  margin: 50px 0px;
  
  .section-heading {
  margin: 0px 0px 30px 0px;
  }
  
  .benefit-row {
  gap: 20px;
  }
  
  .benefit-card {
  background: var(--light-dark);
  padding: 30px 20px;
  height: 250px;
  width: 390px;
  border-radius: 30px;
  
  .benefit_icon {
  height: 50px;
  width: 50px;
  object-fit: fill;
  }
  
  p.title {
  margin-bottom: 0;
  }
  
  p.description {
  font-size: 16px;
  font-weight: 300;
  }
  }
  }
  
  input[type='file'] {
  display: none;
  }
  
  .img-input {
  // background-color: lightgray;
  width: fit-content;
  background-color: #383b3f;
  
  p {
  color: var(--text-black);
  margin-bottom: 0;
  }
  }
  
  table tr .apply-btn {
  border-radius: 25px;
  padding: 5px 25px;
  background: linear-gradient(90deg, #d90a2c 1.05%, #730000 100%);
  font-weight: 500;
  color: white;
  }
  }
  
  .benefits-container {
  padding: 0px 60px;
  
  @media only screen and (max-width: 900px) {
  padding: 0 20px;
  }
  }
  
  .Career-Breadcrem {
  font-weight: bold;
  font-size: 60px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  -webkit-text-stroke: 2px #d90a2c;
  -webkit-text-fill-color: transparent;
  line-height: 1;
  margin-bottom: 20px;
  }
  
  // Application Form
  
  .application_section {
  margin-top: 100px;
  overflow: hidden;
  }
  
  .application-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // margin-top: 20px;
  border-radius: 40px;
  padding: 40px 70px !important;
  // background-color: var(--input-dark) !important;
  
  label {
  color: #fff;
  }
  }
  
  @media only screen and (max-width: 990px) {
  .application-card {
  display: flex;
  flex-direction: column;
  }
  }
  
  // Dashboard Drag and Drop
  
  .dashboard__task__wrapper {
  .dashboardCard {
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  position: relative;
  padding: 25px;
  background: #212529;
  border-radius: 20px;
  transition: all 0.4s ease-in-out;
  
  &:hover {
  background: #0051ef;
  
  .title p {
  color: #fff;
  background: #212529;
  }
  }
  
  .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  
  p {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fafafa;
  transition: all 0.4s ease-in-out;
  }
  
  button {
  color: #fff;
  font-size: 20px;
  border: 0;
  background: transparent;
  }
  }
  
  h6 {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 0;
  color: #ffffff;
  }
  
  p {
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #ffffff;
  }
  
  .contentCard {
  h6 {
  margin-bottom: 10px;
  }
  
  a {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  
  color: #ffffff;
  }
  }
  }
  
  p {
  margin-bottom: 0;
  }
  
  .task-card {
  color: var(--text-white);
  padding: 35px;
  border-radius: 25px;
  height: 100%;
  
  .task-header {
  position: relative;
  }
  
  .task-header::after {
  content: '';
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: -15px;
  left: 0;
  
  background-color: #857878;
  }
  
  li {
  margin-left: 20px;
  // button.active {
  // border-bottom: 1px solid #d90a2c;
  // }
  }
  }
  
  .tasks-content {
  margin-top: 60px;
  position: relative;

  
  .cardItems {
  display: flex;
  flex-direction: column;
  gap: 30px;
  }
  
  .tasks-heading {
  h4 {
  position: relative;
  margin-left: 40px;
  
  &::before {
  content: '';
  height: 20px;
  width: 20px;
  position: absolute;
  background-color: orange;
  border-radius: 100%;
  left: -30px;
  top: 5px;
  }
  
  &.task::before {
  background-color: #e40707;
  }
  
  &.todo::before {
  background-color: #40ecf6;
  }
  
  &.in-progress::before {
  background-color: #f2de2c;
  }
  
  &.done::before {
  background-color: #08a421;
  }
  }
  }
  
  .task-card-item {
  border-radius: 25px;
  background: #141414;
  padding: 15px;
  
  // height: 300px;
  .categories {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  
  .category {
  height: 25px;
  width: fit-content;
  padding: 3px 10px;
  border-radius: 25px;
  background-color: orange;
  cursor: pointer;
  overflow: hidden;
  
  &:nth-child(odd) {
  background-color: #212529;
  }
  
  &:nth-child(even) {
  background-color: #212529;
  }
  
  p {
  font-size: 12px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: var(--text-black);
  }
  }
  
  .category_sprint {
  height: 25px;
  width: fit-content;
  padding: 3px 10px;
  border-radius: 0px;
  background-color: orange;
  cursor: pointer;
  overflow: hidden;
  
  &:nth-child(odd) {
  background-color: #80bbf0;
  }
  
  &:nth-child(even) {
  background-color: #ff90e7;
  }
  
  p {
  font-size: 12px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: var(--text-black);
  }
  }
  }
  
  p.title {
  font-size: 16px;
  font-weight: 500;
  margin: 15px 0px;
  }
  
  p.description {
  font-size: 14px;
  font-weight: 400;
  }
  
  .card-footer {
  display: flex;
  gap: 10px;
  border-top: 1px solid red;
  margin-top: 15px;
  
  .func-btn {
  height: 25px;
  width: fit-content;
  background-color: #212529;
  padding: 1px 10px;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  
  button {
  color: var(--text-white);
  position: relative;
  background: transparent;
  border: 0;
  
  &.view {
  padding: 1px 7px;
  }
  }
  
  button.view::before {
  content: '';
  height: 5px;
  width: 5px;
  background-color: #40ecf6;
  position: absolute;
  left: -5px;
  top: 7px;
  border-radius: 100%;
  }
  }
  }
  }
  }
  
  .filter {
  a {
  cursor: pointer;
  
  p {
  color: var(--text-white);
  font-weight: 500;
  font-size: var(--desc);
  }
  }
  }
  }


  // Admin Dashboard Design

  .Admindashboard__task__wrapper {
    .dashboardCard {
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    position: relative;
    padding: 25px;
    background: #212529;
    border-radius: 20px;
    transition: all 0.4s ease-in-out;
    
    // &:hover {
    // background: #0051ef;
    
    // .title p {
    // color: #fff;
    // background: #212529;
    // }
    // }
    
    .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    
    p {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #fafafa;
    transition: all 0.4s ease-in-out;
    }
    
    button {
    color: #fff;
    font-size: 20px;
    border: 0;
    background: transparent;
    }
    }
    
    h6 {
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 0;
    color: #ffffff;
    }
    
    p {
    font-weight: 600;
    font-size: 25px;
    line-height: 36px;
    color: #ffffff;
    }
    
    .contentCard {
    h6 {
    margin-bottom: 10px;
    }
    
    a {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    
    color: #ffffff;
    }
    }
    }
    
    p {
    margin-bottom: 0;
    }
    
    .task-card {
    color: var(--text-white);
    padding: 35px;
    border-radius: 25px;
    height: 100%;
    
    .task-header {
    position: relative;
    }
    
    .task-header::after {
    content: '';
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: -15px;
    left: 0;
    
    background-color: #857878;
    }
    
    li {
    margin-left: 20px;
    // button.active {
    // border-bottom: 1px solid #d90a2c;
    // }
    }
    }
    
    .tasks-content {
    margin-top: 60px;
    position: relative;
  
    
    .cardItems {
    display: flex;
    flex-direction: column;
    gap: 30px;
    }
    
    .tasks-heading {
    h4 {
    position: relative;
    margin-left: 40px;
    
    &::before {
    content: '';
    height: 20px;
    width: 20px;
    position: absolute;
    background-color: orange;
    border-radius: 100%;
    left: -30px;
    top: 5px;
    }
    
    &.task::before {
    background-color: #e40707;
    }
    
    &.todo::before {
    background-color: #40ecf6;
    }
    
    &.in-progress::before {
    background-color: #f2de2c;
    }
    
    &.done::before {
    background-color: #08a421;
    }
    }
    }
    
    .task-card-item {
    border-radius: 25px;
    background: #141414;
    padding: 15px;
    
    // height: 300px;
    .categories {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    
    .category {
    height: 25px;
    width: fit-content;
    padding: 3px 10px;
    border-radius: 25px;
    background-color: orange;
    cursor: pointer;
    overflow: hidden;
    
    &:nth-child(odd) {
    background-color: #212529;
    }
    
    &:nth-child(even) {
    background-color: #212529;
    }
    
    p {
    font-size: 12px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: var(--text-black);
    }
    }
    
    .category_sprint {
    height: 25px;
    width: fit-content;
    padding: 3px 10px;
    border-radius: 0px;
    background-color: orange;
    cursor: pointer;
    overflow: hidden;
    
    &:nth-child(odd) {
    background-color: #80bbf0;
    }
    
    &:nth-child(even) {
    background-color: #ff90e7;
    }
    
    p {
    font-size: 12px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: var(--text-black);
    }
    }
    }
    
    p.title {
    font-size: 16px;
    font-weight: 500;
    margin: 15px 0px;
    }
    
    p.description {
    font-size: 14px;
    font-weight: 400;
    }
    
    .card-footer {
    display: flex;
    gap: 10px;
    border-top: 1px solid red;
    margin-top: 15px;
    
    .func-btn {
    height: 25px;
    width: fit-content;
    background-color: #212529;
    padding: 1px 10px;
    text-align: center;
    font-size: 13px;
    cursor: pointer;
    
    button {
    color: var(--text-white);
    position: relative;
    background: transparent;
    border: 0;
    
    &.view {
    padding: 1px 7px;
    }
    }
    
    button.view::before {
    content: '';
    height: 5px;
    width: 5px;
    background-color: #40ecf6;
    position: absolute;
    left: -5px;
    top: 7px;
    border-radius: 100%;
    }
    }
    }
    }
    }
    
    .filter {
    a {
    cursor: pointer;
    
    p {
    color: var(--text-white);
    font-weight: 500;
    font-size: var(--desc);
    }
    }
    }
    }
  
  .dashboard_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  }
  
  // User Card
  
  .userCard {
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  position: relative;
  padding: 25px;
  background: transparent;
  border: 1px solid red;
  border-radius: 20px;
  transition: all 0.4s ease-in-out;
  
  .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  
  p {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fafafa;
  transition: all 0.4s ease-in-out;
  }
  
  button {
  color: #fff;
  font-size: 20px;
  border: 0;
  background: transparent;
  }
  }
  
  h6 {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 0;
  color: #ffffff;
  }
  
  p {
  font-weight: 100;
  font-size: 14px;
  // line-height: 5px;
  color: #ffffff;
  }
  
  .contentCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  h6 {
  margin-bottom: 10px;
  }
  
  a {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  
  color: #ffffff;
  }
  }
  
  .mainCard {
  display: flex;
  flex-direction: column;
  }
  
  .infoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  }
  
  .infoCard {
  display: flex;
  flex-direction: column;
  
  h6 {
  margin-top: 20px;
  font-size: 16px;
  line-height: 40px;
  }
  }
  
  .emailInfo {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  }
  }
  
  .benefitText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  p {
  width: 70%;
  text-align: center;
  }
  }
  
  .content_Card {
  border-radius: 25px;
  padding: 20px;
  width: 300px;
  background-color: #212529;
  }
  
  .content_Card:hover {
  background-color: #424242;
  }
  
  .event_Card {
  border-radius: 30px;
  padding: 0px;
  
  img {
  border-radius: 30px 30px 0px 0px;
  }
  
  .span-badge {
  width: 10px;
  height: 10px;
  background: #40ecf6;
  border-radius: 50%;
  margin: 7px;
  }
  }
  
  .Career_Container {
  position: relative;
  text-align: center;
  color: white;
  }
  
  .centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  }
  
  .event_fulimg {
  border-radius: 30px;
  width: 100%;
  height: 550px;
  
  @media only screen and (max-width: 900px) {
  height: 300px;
  }
  }
  
  .Container_eventDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  }
  
  .event_Tag {
  width: 140px;
  height: 48px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  
  p {
  color: white;
  font-size: 20px;
  text-align: center;
  margin-top: 4px;
  }
  }
  
  .ViewCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  h6 {
  font-size: 22px;
  color: white;
  font-weight: 500;
  margin: 0px;
  }
  
  p {
  font-size: 14px;
  }
  }
  
  .ProfileCard {
  display: flex;
  flex-direction: column;
  }
  
  .ProfileContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  .infoCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  }
  
  h6 {
  font-size: 18px;
  color: white;
  font-weight: 400;
  }
  }
  
  .container_Slider {
  position: relative;
  text-align: center;
  color: white;
  }
  
  .centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  padding-bottom: 10rem;
  }
  
  .comming_Events {
  color: white;
  font-size: 22px;
  
  p {
  color: white;
  }
  }
  
  .eventCard_img {
  width: '50%';
  height: '50%';
  }
  
  .carousel-icons {
  background-color: #212529;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  }
  
  .active .carousel-icons {
  background-color: #f50808;
  }
  
  .career-slider {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  }
  
  .event-slider {
  flex-direction: row;
  }
  
  .status {
  height: fit-content;
  width: fit-content;
  padding: 10px 30px;
  border-radius: 35px;
  background-color: #40c4ff;
  color: var(--text-black);
  font-weight: 500;
  }
  
  @media only screen and (max-width: 900px) {
  .career-slider {
  display: flex;
  flex-direction: column;
  
  img {
  // width:300px;
  }
  }
  
  .event-slider {
  display: flex;
  flex-direction: column;
  }
  }
  
  // Modal Classes
  .modal-popups {
  color: var(--text-white);
  
  .Attechment_Card {
  background-color: #212529;
  width: 100%;
  height: auto;
  border-radius: 20px;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  }
  
  .Comment_Card {
  background-color: #212529;
  width: 100%;
  height: auto;
  border-radius: 20px;
  padding: 20px;
  }
  
  .Detail_Card {
  background-color: #212529;
  width: 100%;
  height: auto;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  }
  
  .comment-box {
  width: 100%;
  height: 45px;
  border-radius: 20px;
  padding: 20px;
  border: none;
  }
  
  input,
  textarea {
  color: var(--text-white);
  background: var(--input-dark);
  border-color: var(--dark-dark);
  
  &:focus {
  color: var(--text-white);
  background: var(--input-dark);
  border: 1px solid rgba(206, 9, 39, 0.3);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(206, 9, 39, 0.3);
  }
  }
  
  .modal-footer {
  border-color: var(--dark-dark);
  }
  }
  
  
  .addtask-popups {
  color: var(--text-white);
  
  .addtask-content {
  width: 600px;
  border-radius: 10px;
  background: var(--light-dark);
  position: absolute;
  transform: translate(-151px, 50px);
  height: 500px;
  overflow-y: auto;
  }
  
  .addtask-header {
  background: var(--light-dark);
  // border-radius: 10px;
  // border-color: var(--dark-dark);
  border: none;
  margin-top: 20px;
  margin-left: 20px;
  }
  
  .addtask_header {
  margin: 2rem;
  margin-bottom: 0;
  }
  
  input,
  textarea {
  color: var(--text-white);
  background: var(--input-dark);
  border-color: var(--dark-dark);
  
  &:focus {
  color: var(--text-white);
  background: var(--input-dark);
  border: 1px solid rgba(206, 9, 39, 0.3);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(206, 9, 39, 0.3);
  }
  }
  
  .addtask-footer {
  border-color: var(--dark-dark);
  }
  }
  
  .status_btn {
  width: fit-content;
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  background: aquamarine;
  text-align: center;
  }
  
  .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
  margin-left: 14px;
  }
  
  .custom-file-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  height: 100%;
  width: 100%;
  }
  
  .attachmant-file {
  position: absolute;
  z-index: -1;
  opacity: 0;
  }
  
  .custom-file-label {
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #212121;
  border: 1px solid #ced4da;
  border-style: dashed;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 80px;
  }
  
  .custom-file-label::after {
  content: 'Browse';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: none;
  }
  
  .custom-file-input:focus+.custom-file-label,
  .custom-file-input.is-valid+.custom-file-label,
  .custom-file-input.is-invalid+.custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .custom-file-input.is-valid~.valid-feedback,
  .custom-file-input.is-valid~.invalid-feedback,
  .custom-file-input.is-invalid~.valid-feedback,
  .custom-file-input.is-invalid~.invalid-feedback {
  display: block;
  }
  
  .custom-file-input:focus~.custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  #check {
  display: none;
  }
  
  label #btn label #cancel {
  position: absolute;
  cursor: pointer;
  }
  
  label #btn {
  left: 40px;
  top: 25px;
  font-size: 35px;
  color: white;
  padding: 6px 12px;
  cursor: pointer;
  }
  
  label #cancel {
  z-index: 1111;
  left: 195px;
  font-size: 30px;
  color: white;
  padding: 4px 9px;
  cursor: pointer;
  }
  
  .my-modal {
  min-height: 400px;
  }
  
  .crsl-btn {
  background-color: #d90a2c;
  }
  
  .detail_btn {
  margin-left: -35px;
  }
  
  .sidebar_detail {
  color: white;
  background: transparent;
  }
  
  @media only screen and (max-width: 900px) {
  .modal-content {
  max-width: 600px;
  // margin-left: 100px;
  }
  }
  
  @media only screen and (min-width: 650px) {
  .modal-content {
  min-width: 300px;
  // margin-left: 100px;
  }
  }
  
  .slider_Cardimg {
  width: '500px';
  height: '300px';
  }
  
  .tippy_content {
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
  }
  
  .view_input {
  font-size: 25px;
  font-weight: bold;
  border: none;
  width: 100%;
  margin-left: 30px;
  margin-top: 20px;
  }
  
  .view_input:hover {
  border: 2px solid black;
  }
  
  .view_description {
  font-size: 15px;
  border: 1px solid black;
  width: 100%;
  height: 100px;
  }
  
  .view_sprint {
  font-size: 15px;
  border: none;
  width: 08%;
  text-align: center;
  height: 40px;
  }
  
  .view_sprint:hover {
  border: 1px solid black;
  }
  
  .view_time {
  font-size: 15px;
  border: none;
  width: 50%;
  }
  
  .view_time:hover {
  border: 2px solid black;
  }
  
  .div-box {
  background-color: transparent;
  border: none;
  appearance: none;
  }
  
  .div-box:hover {
  border: 2px solid black;
  }
  
  .image-container {
  position: relative;
  display: inline-block;
  }
  
  .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  display: none;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.5);
  }
  
  .image-container:hover .overlay {
  opacity: 1;
  display: block;
  }
  
  .edit-icon,
  .delete-icon {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  }
  
  .delete-icon {
  left: 50%;
  }
  
  .img-span {
  width: 120px;
  height: 80px;
  border: 2px solid rgb(61, 61, 61);
  text-align: center;
  }
  
  .img-div {
  transform: translate(0px, 12px);
  cursor: pointer;
  }
  
  .img-fld {
  position: absolute;
  right: 10px;
  height: 25px;
  width: 25px;
  }
  
  .dropdown-menu {
  display: none;
  }
  
  .dropdown:hover .dropdown-menu {
  display: block;
  }
  
  .tech-card {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  }
  
  .btn-url {
  color: white;
  background: transparent;
  border: none;
  }
  
  .prjt-btn {
  background-color: transparent;
  color: white;
  border: #d90a2c;
  border-radius: 30px;
  width: 60px;
  }
  
  .modal-content {
  height: auto;
  }
  
  .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem 2.5rem;
  overflow: hidden;
  }
  
  .feed-heading {
  margin-top: 7rem;
  font-weight: bold;
  font-size: 60px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  -webkit-text-stroke: 2px #d90a2c;
  -webkit-text-fill-color: transparent;
  line-height: 1;
  }
  
  .feed-input {
  width: 100%;
  height: 45px;
  background: transparent;
  border: none;
  }
  
  .Technologies {
  position: fixed;
  bottom: 18px;
  width: 50%;
  color: white;
  }
  
  @media screen and (max-width: 1200px) {
  .career__wrapper .benefits .benefit-row {
  justify-content: center;
  }
  }
  
  @media screen and (max-width: 1050px) {
  .benefits-cards {
  justify-content: center;
  }
  }
  
  @media screen and (max-width: 768px) {
  .Detail_Card {
  display: flex;
  flex-direction: column;
  }
  
  .view_time {
  width: 10%;
  }
  
  .tech-card {
  display: flex;
  flex-direction: column;
  }
  
  .btn-url {
  width: 120px;
  margin-bottom: 20px;
  }
  
  .career__wrapper .benefits .benefit-row {
  justify-content: center;
  }
  }
  
  @media screen and (max-width: 576px) {
  .dashboard__task__wrapper .tasks-content .task-card-item {
  .p {
  font-size: 10px;
  }
  
  p.title {
  font-size: 12px;
  }
  
  p.description {
  font-size: 12px;
  // font-weight: 400;
  }
  }
  
  .dashboard__task__wrapper .task-card {
  padding: 0px;
  }
  
  .dashboard__task__wrapper .tasks-content .task-card-item {
  position: relative;
  left: 10px;
  margin-bottom: 20px;
  }
  
  .dashboard_header {
  padding-top: 2.5rem;
  display: inherit;
  }
  
  .career__wrapper .benefits .benefit-row {
  justify-content: center;
  }
  
  .benefits-cards {
  justify-content: center;
  }
  }
  
  .project-btn {
  width: 30%;
  height: 8%;
  border-radius: 20px;
  border: none;
  background: #51afff;
  color: white;
  text-align: center;
  }
  
  .application-title {
  color: #fff;
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 20px;
  width: 100%;
  }
  
  .time {
  font-size: 14px !important;
  margin-top: 2px;
  }
  
  .socials i {
  margin-right: 14px;
  font-size: 17px;
  color: #d2c8c8;
  cursor: pointer;
  }
  
  .feed-image img {
  width: 100%;
  height: auto;
  border-radius: 20px;
  }
  
  .feed-card {
  border-radius: 20px;
}

.feed-content {
  // margin-left: 3rem;
}

.feed-social {
  display: flex;
  justify-content: start;
  gap: 40px;
}

.feed-title {
  color: white;
  font-size: large;
  font-weight: 500;
  margin-bottom: 0px;
  }
  
  .post {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  }
  
  .single-image {
  max-height: 350px;
  width: 100%;
  object-fit: fill;
  padding: 10px;
  }
  
  .grid {
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // grid-gap: 5px;
  // padding: 20px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  /* align-items: center; */
  width: 800px;
  /* height: 288px; */
  /* background-color: #f8f8f8; */
  padding: 5px;
  }
  
  .grid-2 {
  grid-template-columns: repeat(2, 1fr);
  }
  
  .grid-3s {
  grid-template-columns: repeat(3, 1fr);
  }
  
  .grid-4 {
  grid-template-columns: repeat(2, 1fr);
  }
  
  .grid-5 {
  grid-template-columns: repeat(3, 1fr);
  }
  
  .grid-6 {
  grid-template-columns: repeat(3, 1fr);
  }
  
  .grid-7 {
  grid-template-columns: repeat(4, 1fr);
  }
  
  .grid-8 {
  grid-template-columns: repeat(4, 1fr);
  }
  
  .grid-9 {
  grid-template-columns: repeat(3, 1fr);
  }
  
  .multiple-image {
  width: 48%;
  height: auto;
  margin: 2px;
  position: relative;
  // object-fit: cover;
  // flex: 1;
  }
  
  .image-2 {
  grid-column: span 2;
  grid-row: span 1;
  }
  
  .image-3 {
  grid-column: span 3;
  grid-row: span 1;
  }
  
  .image-4 {
  grid-column: span 2;
  grid-row: span 2;
  }
  
  .image-5 {
  grid-column: span 3;
  grid-row: span 2;
  }
  
  .image-6 {
  grid-column: span 3;
  grid-row: span 2;
  }
  
  .image-7 {
  grid-column: span 4;
  grid-row: span 2;
  }
  
  .image-8 {
  grid-column: span 4;
  grid-row: span 2;
  }
  
  .image-9 {
  grid-column: span 3;
  grid-row: span 3;
  }
  
  // .card-twitter {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  // align-items: center;
  // width: 800px; /* set the desired width */
  // height: 400px; /* set the desired height */
  // background-color: #f8f8f8;
  // padding: 10px;
  // }
  // img {
  // width: calc(33.33% - 10px);
  // height: calc(100% - 20px);
  // object-fit: cover;
  // object-position: center;
  // margin: 0 5px;
  // }
  
  .react-slideshow-container .nav:last-of-type {
  right: 40px;
  }
  
  .slick-slide>div {
  margin: 0 10px;
  }
  
  .slick-list {
  margin: 0 -10px;
  }
  
  .event-card {
  border: 1px solid #575757;
  background: #212529;
  border-radius: 8px;
  overflow: hidden;
  
  height: 400px;
  color: #fff;
  cursor: pointer;
  }
  
  .card-top h1 {
  font-size: 1.3rem;
  margin: 20px;
  }
  
  .card-top>img {
  width: 100%;
  height: 200px;
  object-fit: fill;
  background: black;
  }
  
  .card-bottom {
  margin: 10px 20px;
  }
  
  .category {
  position: relative;
  background: transparent;
  overflow: hidden;
  }
  
  .category::before {
  content: '';
  background: rgb(255, 61, 61);
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  right: 0;
  height: 2px;
  }
  
  .btn-container {
  display: flex;
  gap: 5px;
  margin-left: 15px;
  }
  
  .event-btn {
  background: transparent;
  border: none;
  color: #d90a2c;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  z-index: 1;
  position: relative;
  }
  
  .event-img {
  margin-top: 5px;
  }
  
  .event-btn:hover {
  font-weight: 700;
  }
  
  .event-img:hover {
  margin-left: 10px;
  }
  
  .feed-imgcrd {
  display: flex;
  flex-direction: row;
  width: 50%;
  height: auto;
  }
  
  .benefits-cards {
  gap: 20px;
  margin-bottom: 40px;
  }
  
  .application-form {
  border-radius: 25px;
  padding: 35px 35px !important;
  background: linear-gradient(90deg, #d90a2c 1.05%, #730000 100%);
  height: fit-content;
}

.card-text {
  // padding: 100px 45px;

  @media (max-width: 800px) {
  padding: 10px;
  }
  }
  
  // Project carousel
  
  .carousel-control {
  background: transparent;
  border: none;
  font-size: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  }
  
  .carousel-control.left {
  left: 10px;
  }
  
  .carousel-control.right {
  right: 10px;
  }
  
  .padding-20 {
  padding-left: 20px;
  padding-right: 20px;
  }
  
  .table-responsive>* {
  @media (max-width: 800px) {
  width: auto !important;
  }
  }
  
  a {
  color: #d90a2c;
  }
  
  a:hover,
  a:active {
  color: #d90a2c;
  }
  
  span.undefined {
  color: #fff;
  }
  
  
  .single-video {
  max-height: 350px;
  width: 100%;
  padding: 10px;
  }
  
  body {
  overflow-x: hidden;
  }
  
  .padding2040 {
  padding: 20px 40px;
  }
  
  .loan-form {
  justify-content: space-between;
  align-items: center;
  
  margin: 25px 0;
  
  .annexues {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #000
  }
  
  label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  margin-bottom: 20px;
  }
  
  input {
  border: none;
  border-bottom: 1px solid #000;
  font-size: 20px;
  }
  
  .form-div_date {
  flex: 1 !important;
  }
  
  .form-div {
  flex: 0.2;
  
  img {
  max-width: 250px;
  }
  
  &>* {
  width: 100%;
  }
  
  h3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  
  }
  
  p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  
  }
  }
  }
  
  .form__border-bottom {
  border-bottom: 1px solid #A5A5A5;
  }
  
  .form-div.row>* {
  width: 50%;
  }
  
  .form-div.row {
  margin-bottom: 20px;
  }
  
  .date-input {
  text-align: right;
  
  span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  }
  
  }
  
  .form-flex04 {
  flex: 0.4 !important;
  padding: 0;
  }
  
  .big-heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  }
  
  .checkbox-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  }
  
  .form-div_amount.form-flex04 {
  .col-lg-2 {
  display: inline-block;
  width: 30%;
  }
  }
  
  .relation-input {
  input {
  width: 75%;
  margin-left: 40px;
  }
  }
  
  $fontSize: 20px;
  $lineWidth: 1px;
  $lineColor: #000;
  $backgroundColor: #FFF;
  $xPadding: 10px;
  
  .textbox-lines {
  width: 100%;
  max-width: 100%;
  font-size: $fontSize;
  border: none;
  background-attachment: local;
  background-image:
  linear-gradient(to right, $backgroundColor, $backgroundColor $xPadding, transparent $xPadding),
  linear-gradient(to left, $backgroundColor, $backgroundColor $xPadding, transparent $xPadding),
  repeating-linear-gradient($backgroundColor,
  $backgroundColor $fontSize*3-$lineWidth,
  $lineColor $fontSize*3-$lineWidth,
  $lineColor $fontSize*3 );
  line-height: $fontSize*3;
  padding: $fontSize/3 $xPadding;
  resize: none;
  height: 210px;
  border-radius: 0;
  }
  
  .eligible-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 28px;
  color: #000000;
  }
  
  .eligible-ul {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  
  list-style-type: disc;
  margin-left: 30px;
  
  li::marker {
  font-size: 35px;
  }
  }
  
  .undertaking {
  list-style: auto;
  
  li::marker {
  font-size: 20px;
  font-weight: 600;
  }
  
  li {
  margin: 10px 0;
  }
}

.border_bottom {
  border-bottom: 2px solid #000;
}

.event_action-btn{
  padding: 7px;
  background-color: #D4D4D4;
  border-radius: 50%;
}

.benefits-btn{
  display: none !important;
  z-index: 999;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  background:#1a1a1aba;
  gap: 10px;
  border-radius: 10px;
}

.single-service:hover .benefits-btn{
  display: flex !important;
}
.event_Card:hover .event-action-btns {
  display: flex !important;
}

.userCard:hover .user-action_btn{
  display: flex !important;
}

.skills-container{
  background: transparent;
  border: 2px solid #d90a2c;
  padding: 10px;
  border-radius: 12px;
  width: 100%;
  min-height: 150px;
  text-align: start;
  .skills-item {
    text-align: left;
    background: linear-gradient(90deg, #d90a2c 1.05%, #730000 100%);
    padding: 0.5rem 0.7rem;
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    .skill-text{
      font-weight: 500;
      color: #fff;
    }
    .skill-close{
      background: #2f2e2e;
      color: #fff;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: inline-flex;
      justify-items: center;
      align-items: center;
      text-align: center;
      font-size: 12px;
      margin-left: 8px;
      padding-left: 5px;
    }
  }
  .skills-input{
    outline: none;
    border: none;
    padding: 0.5rem 0;
    flex-grow: 1;
    background: transparent;
    color: white;
  }
}

.bg-input option{
  background:  var(--input-dark) !important;
}

.document-buttons{
  display: flex;
    gap: 15px;
}

.status-dropdown{
  margin-top: 10px;
  position: absolute;
  background: #111;
  padding: 20px 30px 20px 10px;
  border-radius: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.status{
  cursor: pointer;
}

.status-dropdown > p {
  cursor: pointer;
  color: #fff;
}
.status-dropdown > p:hover{
  color: #d90a2c;
}
.modal-content{
  margin-top: 60px;
  border-radius: 15px;
}
.comment-input{    background-color: rgb(33, 37, 41);
  width: 100%;
border: 1px solid #f4f4f4;
  border-radius: 20px;
  padding: 10px;
  outline: none;
  color: white;}

  .comment-box{

    flex-direction: column;
    width: 100%;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 10px;
    gap: 10px;
  }
  .comment_user-name{
    font-weight: 700;
  }
.comment_content{

  font-size: 14px;
  opacity: 0.8;
  padding-left: 20px;
}
.comment_avatar{
  border-radius: 50%;
    margin-right: 8px;
}
.bg-dark.border-0.mt-2.feed-card  {
  padding-bottom: 20px;
}



.post-liked{
  color:red !important;
}

.margin-left-40{
  margin-left: 40px !important;
}

.blog_user-img{
  border-radius: 50% !important;
  width: 55px;
  height: 55px;
  object-fit: cover;
}
.PhoneInputInput{
  border: none;
}
input.PhoneInputInput{
  background: white;
  color: black;
}
input.PhoneInputInput:focus{
  background: white;
  color: black;
  border: none;
  box-shadow: none;
}

