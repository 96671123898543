::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: #aaa;
}

::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 8px;
}

body {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: black;
  column-gap: 3px;
}
.login-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1000px;
  background: linear-gradient(90deg, #d90a2c 1.05%, #730000 100%);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin: 7rem auto;
  border-radius: 12px;
}
.login-form {
  flex: 1 0 100%;
  max-width: 420px;
  // width: 80%;
  padding: 60px;
}
.login-form p {
  margin-bottom: 30px;
  text-align: center;
  color: #fff;
  font-family: 'Saira', sans-serif;
}
.login-form p.form-title {
  color: #fff;
  font-family: 'Saira', sans-serif;
  font-size: 42px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
  text-align: center;
}
.login-form label {
  color: #fff;
  font-family: 'Saira', sans-serif;
}
.login-form .ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}
.login-form input {
  border-radius: 30px;
  padding: 10px 30px;
  border-color: #d90a2c;
  font-family: 'Saira', sans-serif;
  border: 0;
}
.ant-form-item-control-input-content {
  text-align: left;
  border-radius: 30px;
}
.login-form .ant-input-affix-wrapper {
  padding: 12px 15px;
  border-radius: 30px;
}
.login-form_username {
  height: 48px;
  border-radius: 30px;
}
.login-form .ant-btn {
  height: 42px;
  letter-spacing: 1px;
  border-radius: 6px;
}
.login-form-button {
  width: 100%;
  margin-top: 3vh;
  border-radius: 30px;
  padding: 10px 30px;
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  background: #212529;
  z-index: 1;
  transition: all 0.5s ease-out 0s;
  border: none;
  font-family: 'Saira', sans-serif;
}
login-form-button:hover {
  text-decoration: none;
}
.illustration-wrapper {
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  min-height: 100%;
  background-color: #fffdf2;
}
.illustration-wrapper img {
  display: block;
  width: 90%;
}
@media screen and (max-width: 1023px) {
  .login-box {
    flex-direction: column;
    box-shadow: none;
  }
  .illustration-wrapper {
    max-width: 100%;
    min-height: auto;
  }
  #login-form {
    max-width: 100%;
  }
}
