/*===========================
     01.COMMON CSS 
===========================*/

@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  outline: 0 solid;
}

// html,
// body {
//   height: 100%;
// }

body {
  // cursor: none;
  font-size: 16px;
  font-family: 'Saira', sans-serif;
  background: #000 !important;
  cursor: unset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-bottom: 20px;
}

img {
  max-width: 100%;
}

a,
ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

p {
  color: #787878;
  line-height: 1.6;
}

a,
a:hover {
  text-decoration: none;
}

input,
select,
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

.sec-pad {
  padding: 40px 0;
}

.sec-pad-top {
  padding-top: 120px;
}

.sec-pad-bottom {
  padding-bottom: 120px;
}

.sec-mar {
  margin: 50px 0;
}

.sec-mar-top {
  margin-top: 40px;
}

.sec-mar-bottom {
  margin-bottom: 100px;
}

//Mouse cursor
.cursor {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid $theme-color;
  transition: all 200ms ease-out;
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  transform: translate(calc(-50% - 100px), -50%);
  z-index: 9;
}

.cursor2 {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: $theme-color;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: width 0.3s, height 0.3s, opacity 0.3s;
  z-index: 9;
}

.hover {
  background-color: $theme-color;
  height: 70px;
  width: 70px;
  border: 1px solid transparent;
  opacity: 0.3;
  z-index: 9999;
}

.cursorinnerhover {
  width: 2px;
  height: 2px;
  opacity: 0.5;
}
.mb-15 {
  margin-bottom: 15px !important;
}
/* Preloader css */

.sk-cube-grid {
  width: 80px;
  height: 80px;
  margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: $theme-color;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.title {
  color: #fff;
  font-size: var(--title);
  font-weight: 500;
}
.main-title {
  color: #fff;
  font-size: var(--main-title);
  font-weight: 500;
  margin-bottom: 0;
}
.heading {
  color: #fff;
  font-size: var(--heading);
  font-weight: 500;
  margin-bottom: 0;
}
.description {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
}

.text-highlighted {
  color: white;
  transition: 0.5s all ease;
  margin-left: 20px;
  margin-top: 5px;

  &.active,
  &:hover {
    margin-left: 20px;
    margin-top: 15px;
    color: red;
  }
}

input.bd-input {
  border-radius: 0.75rem;
  padding: 8px 12px;
  background-color: white;
  border: none;
  color: black;
  margin-bottom: 1rem;

  &:focus {
    background-color: white;
    color: black;
    border: 1px solid #812a2a;
    box-shadow: 0 0 0 0.25rem rgba(206, 9, 39, 0.3);
  }
}

input.bd-customfile {
  border-radius: 10px;
  padding: 15px 30px;
  background-color: var(--black);
  border: 1px dotted var(--dark-dark);
  color: #fff;
  margin-bottom: 25px;
  height: 6rem;

  &:focus {
    background-color: var(--black);
    color: white;
    border: 1px solid #812a2a;
    box-shadow: 0 0 0 0.25rem rgba(206, 9, 39, 0.3);
  }
}

select.bd-input {
  border-radius: 0.75rem;
  padding: 8px 12px;
  background-color: white;
  border: none;
  color: black;
  margin-bottom: 10px;

  &:focus {
    background-color: white;
    color: black;
    border: 1px solid #812a2a;
    box-shadow: 0 0 0 0.25rem rgba(206, 9, 39, 0.3);
  }
}

.custom_pagination {
  .pagination_text {
    margin: 0px 20px;
  }
  input {
    background: #212529;
    border-color: #000;
    width: 100px;
    color: #fff;

    &:focus {
      background-color: #212529;
      color: #fff;
      text-align: center;
      border-color: rgb(206, 9, 39 / 0.5);
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba(206, 9, 39, 0.5);
    }
  }
  p.total-pages {
    color: #fff;
    margin-right: 20px;
    font-weight: 300;
  }

  button.page-link {
    background: #212529;
    color: #fff;
    border-color: #000;

    &:focus {
      border-color: rgb(206, 9, 39 / 0.5);
      outline: 0;
      box-shadow: 0 0 0 0.1rem rgba(206, 9, 39, 0.5);
    }
  }
}
.bd-table {
  color: var(--text-white);
  thead {
    background: transparent;
    color: #fff;
    tr > th:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    tr > th:last-child {
      border-top-right-radius: 10px;
      // border-bottom-right-radius: 10px;
    }
    th > p {
      color: var(--text-white);
      margin-top: 10px;
      margin-bottom: 10px;
    }
    th:first-child > p {
      margin-left: 20px;
    }
  }
  tbody {
    color: #fff;
    margin-top: 20px !important;
    td.position {
      width: 15%;

    }
    // td.desc {
    //   // width: 600px;
    // }
    td.location {
      margin-left: 20px;
      @media #{$md,$xs} {
        margin-left: 0px;
      }
    }
    td {
      div.status {
        height: fit-content;
        width: fit-content;
        padding: 10px 30px;
        border-radius: 35px;
        background-color: #40c4ff;
        color: var(--text-black);
        font-weight: 500;

        &.approve {
          background-color: #8ef186;
        }
        &.awaiting {
          background-color: #40c4ff;
        }
        &.reject {
          background-color: #d90a2c;
        }
        &.approve {
          background-color: #8ef186;
        }
      }
    }
    td:last-child {
      position: relative;
    }

    tr {
      td {
        padding-bottom: 25px;
        p {
          color: var(--text-white);
          // margin-left: 20px;
          margin-bottom: 0px;
        }
      }
    }

    .no_hours {
      // background: orange;
      width: fit-content;
      p {
        position: relative;
        // color: orange;
        &:before {
          content: '';
          position: absolute;
          height: 8px;
          width: 8px;
          left: -15px;
          top: 8px;
          // margin-right: 20px;
          border-radius: 100%;
          background-color: #40ecf6;
        }
      }
    }
  }
}

.bottom-line{
  border-bottom: 0.5px solid #e10a0a;
}




@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

/* customize preloader */

@keyframes hover {
  0% {
    transform: scale(0.5);
    color: #121212;
    -webkit-text-stroke: 2px gray;
  }

  20% {
    transform: scale(1);
    color: pink;
    -webkit-text-stroke: 3px red;
    filter: drop-shadow(0 0 1px black) drop-shadow(0 0 1px black)
      drop-shadow(0 0 3px red) drop-shadow(0 0 5px red) hue-rotate(10turn);
  }

  50% {
    transform: scale(0.5);
    color: #121212;
    -webkit-text-stroke: 2px gray;
  }
}

.preloader_area_wrap {
  background: #282b34 none repeat scroll 0 0;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999999;
}

.preloader_area_wrap .sk-cube-grid {
  left: 50%;
  margin: -20px 0 0 -20px;
  position: absolute;
  top: 50%;
}

//preloader css end

//Sticky menu
.sticky {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 100px;
  width: 100%;
  z-index: 99;
  background-color: #121212;

  @media #{$laptop} {
    padding: 0px 50px;
  }

  @media #{$lg,$md} {
    padding: 10px 20px;
  }

  @media #{$xs} {
    padding: 20px 0;
  }
}

//Title css
.title {
  // @media #{$xs} {
  //   text-align: center;
  // }

  @media #{$sm} {
    text-align: inherit;
  }

  span {
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: $theme-color;
    margin-bottom: 15px;
    text-transform: uppercase;
    &:before {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0px;
      width: 100%;
      height: 1px;
      background-color: $theme-color;
    }
  }

  h2 {
    font-size: 52px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 30px;
    text-transform: capitalize;

    @media #{$laptop,$lg} {
      font-size: 48px;
    }

    @media #{$xs} {
      font-size: 30px;
    }
  }

  &.black h2 {
    color: #17161a;
  }

  &.special h2 {
    text-transform: uppercase;
    margin: 0;

    b {
      font-weight: bold;
      font-size: 65px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      -webkit-text-stroke: 2px #d90a2c;
      -webkit-text-fill-color: transparent;

      @media #{$md} {
        font-size: 55px;
      }

      @media #{$xs} {
        font-size: 50px;
      }
    }
  }
}

//Common button css
.cmn-btn {
  display: inline-flex;
  button,
  a {
    border: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    border-radius: 30px;
    padding: 10px 18px;
    color: #fff;
    font-size: 16px;
    text-transform: capitalize;
    background: linear-gradient(90deg, #d90a2c 1.05%, #730000 100%);
    z-index: 1;
    @include transition(0.5s);

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 0%;
      top: 50%;
      left: 50%;
      background-color: #000000;
      transform: translate(-50%, -50%) rotate(-45deg);
      z-index: -1;
      @include transition(0.5s);
    }

    &:hover {
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);

      &:before {
        height: 380%;
      }
    }
  }
}

//Login btn
.login-btn {
  display: inline-flex;
  button,
  a {
    border: 0;
    overflow: hidden;
    position: relative;
    display: inline-block;
    border-radius: 30px;
    padding: 10px 30px;
    color: #fff;
    font-size: 16px;
    text-transform: capitalize;
    background: linear-gradient(90deg, #d90a2c 1.05%, #730000 100%);
    z-index: 1;
    @include transition(0.5s);

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 0%;
      top: 50%;
      left: 50%;
      background-color: #000000;
      transform: translate(-50%, -50%) rotate(-45deg);
      z-index: -1;
      @include transition(0.5s);
    }

    &:hover {
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);

      &:before {
        height: 380%;
      }
    }
  }
}


//Breadcrumbs css
.breadcrumbs {
  height: 280px;
  background-image: url(../../../public/images/breadcrumbs-bg.png);
  background-size: cover;
  background-position: center center;

  @media #{$xs} {
    text-align: center;
    height: 300px;
  }
}

.breadcrumb-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 350px;


  @media #{$xs} {
    text-align: center;
    min-height: 350px;
  }

  h1 {
    font-weight: bold;
    font-size: 60px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    -webkit-text-stroke: 2px #d90a2c;
    -webkit-text-fill-color: transparent;
    line-height: 1;
    margin-bottom: 20px;

    @media #{$xs} {
      font-size: 40px;
    }
  }

  span {
    font-size: 15px;
    text-transform: capitalize;
    color: #ffffff;

    a {
      display: inline-block;
      color: $theme-color;
    }

    i {
      margin: 0 10px;
    }
  }
}

.scroll-top.opacity {
  position: fixed;
  right: 20px;
  bottom: 50px;
  z-index: 9;
  text-align: center;
  display: block;

  span {
    height: 40px;
    width: 40px;
    display: block;
    border: 1px solid #d90a2c;
    border-radius: 50%;
    color: #d90a2c;
    position: relative;
    line-height: 40px;
    font-size: 11px;
    cursor: pointer;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;
    &:hover i {
      top: -25px;
    }

    i {
      position: absolute;
      top: -17px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 16px;
      transition: 0.3s ease-in-out;
    }
  }
}


//Table
.customTable {
  position: relative;
  thead {
    width: 100%;
    background: transparent;
    border-radius: 20px;
    th {
      text-align: center;
      border: 0;
      padding: 30px 10px;
      background: transparent;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      &:first-child {
        border-radius: 20px 0 0 20px;
      }
      &:last-child {
        border-radius: 0 20px 20px 0;
      }
      &:hover {
        background-color: transparent; /* Make the background transparent on hover */
      }
    }
  }
 
  tbody td {
    padding: 30px 10px;
    background: transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    .status {
      height: fit-content;
      width: fit-content;
      padding: 10px 30px;
      border-radius: 35px;
      background-color: #40c4ff;
      color: var(--text-black);
      font-weight: 500;

      &.awaiting {
        background-color: #40c4ff;
      }
      &.reject {
        background-color: #d90a2c;
      }
      &.approve {
        background-color: #8ef186;
      }
      &.vip {
        background-color: #6cf95f;
      }
      &.standard {
        background-color: #40ecf6;
      }
      &.mondatory {
        background-color: #f2de2c;
      }
    }
  }
}
.dotDiv {
  position: relative;
  span {
    display: block;
    width: 10px;
    height: 10px;
    background: #40ecf6;
    border-radius: 50%;
    margin-right: 10px;
    &.deliverd {
      background: #08a421;
    }
    &.moderate,
    &.pending {
      background: #eedc3d;
    }
    &.poor {
      background-color: #d90a2c;
    }
    &.good {
      background-color: #8ef186;
    }
  }
}
.input.form-control {
  background: transparent;
  color: #fff;
  border: 1px solid #ff0707;
  text-align: center;
}

.view_profile {
  position: relative;
  //padding: 15px 0 0;
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
}
.profile-input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid $gray;
  outline: 0;
  // font-size: 1.3rem;
  color: $white;
  //padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.tableHeading {
  position: relative;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #ffffff;
}

.sidebar_select{
  padding: 10px 20px;
  border-radius: 30px;
  border: none;
  background: #212529;
  color: white;
}

.PaytableHeading{
  position: relative;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #ffffff;
}
@media only screen and (min-width: 750px)  {
tr:hover{
  height: 50px;
  background: linear-gradient(
    152.97deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 20px;
}}
.tr__border:hover{
  height: 2px !important;
  background-color: transparent;
}
@media only screen and (min-width: 750px)  {.leads_row:hover{
  background: linear-gradient(
    152.97deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}}

.table__headRow {
background-color: transparent !important;
@media screen and (max-width: 780px) {
  display: none !important;
}
}

table th:hover {
  background-color: transparent;
  cursor: default;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// . > *{

// }

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// Sidebar Responsive

.sidebar_res {
  position: absolute;
  width: 16.7%;
}
.main_sidebar {
  width: 17%;
}
.toggle_btn {
  margin-left: -30px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .sidebar_res {
    width: 0px;
  }
  .cross-btn {
    display: inline-block !important;
    position: relative;
    width: 30px !important;
    height: 22px !important;
    cursor: pointer;
    border: 3px solid transparent !important;
  }
  .cross-btn span {
    width: 100%;
    height: 2px;
    background: linear-gradient(
      233.77deg,
      rgba(115, 0, 0, 0.8) 0.94%,
      rgba(217, 10, 44, 0.8) 99.09%
    ) !important;
    display: block;
    position: absolute;
    right: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .cross-btn .cross-top {
    top: 0;
  }
  .cross-btn .cross-middle {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
  }
  .cross-btn .cross-bottom {
    bottom: 0;
    width: 100%;
  }
  .cross-btn.h-active span.cross-top {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 50%;
    margin-top: -1px;
  }
  .cross-btn.h-active span.cross-middle {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
    opacity: 0;
  }
  .cross-btn.h-active span.cross-bottom {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    bottom: 50%;
    margin-bottom: -1px;
  }
}

.Statu {
  display: block;
  width: 10px;
  height: 10px;
  background: #40ecf6;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 10px;
}

.table-footer{
  display: flex;
  gap:20px;
}

.card-container{
  height: 370px;
  background-color: #212529;
}
.card-container:hover{
  background-color: #363636;
  margin-top: 10px;
}
.div_img{
  padding: 30px;
}
.card_data{
  padding-left: 30px;
}
.card_data p{
  padding-right:20px ;
}

.col-footer{
  flex: 1 0;
}

@media screen and (max-width: 576px) {
  .customTable thead th{
    padding: 15px 25px;
    font-size: 14px;
    line-height: 19px;
  }
  .customTable tbody td{
    font-size: 14px;
  }
  .tableHeading{
    font-size: 18px;
    padding-left: 2rem;
  }
  .PaytableHeading{
    font-size: 18px;
    padding-left: 2rem;
    padding-top: 3rem;
  }
  .cmn-btn {
    display: flex;
    justify-content: end;
    padding-right: 2rem;
    
    @media #{$xs}{
      justify-content: center;
    }
  }
  .col-footer{
    width: 100%;
  }
  .col-th{
    flex:0;
  }
  .customTable tbody td .status{
    width: 140px;
  }
}


.table > :not(caption) > * > *{border-bottom-width:0 !important}
hr{opacity: 1 !important}



.highlighted_underline {
  display: flex;
  justify-content: center;
  color: white;
  width: 100%;
  position: relative;
  li:hover{
    background-color: rgb(159, 30, 30);
    cursor: pointer;
  }
  .start_btn {
    flex-grow: 1;
    padding: 12px;
    border: none;
    border: 1px solid red;
    border-radius: 30px 0px 0px 30px;
    text-align: center;
  }
  .middle_btn {
    flex-grow: 1;
    padding: 12px;
    border: none;
    text-align: center;
    border: 1px solid red;
  }
  .last_btn {
    flex-grow: 1;
    padding: 12px;
    border: none;
    border-radius: 0px 30px 30px 0px;
    text-align: center;
    border: 1px solid red;
  }
}




@media (max-width: 768px) {
  .start_btn {
    font-size: 10px;
  }
  .middle_btn {
    font-size: 10px;
  }
  .last_btn {
    font-size: 10px;
  }
}
.tab_active{
  background: linear-gradient(90deg, #d90a2c 1.05%, #730000 100%);
}
.select-wrapper{
  position: relative;
}

.lead_selector{
  appearance: none;
  -webkit-appearance: none; 
  -moz-appearance: none; 
  width: 100%;
  padding: 15px 20px;
  // border-radius: 20px;
  background: transparent;
  border: none;
  border-left: 1px solid rgb(158, 28, 28);
  outline: none;
  color:white;
  cursor: pointer;
  background-image: url("../images/drop-down.png");
  filter: brightness(0) saturate(100%) invert(100%);
  background-repeat: no-repeat;
  background-position: right center; 
  background-position: calc(100% - 30px) center; 
  -webkit-appearance: none;
  appearance: none;
  height: 3.3rem;
  background-size: 20px;
  option{
    background-color: #363636;
  }
}

.lead_selector::after {
  content: ""; 
  position: absolute;
  top: 50%;
  right: 10px; 
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent; 
  border-right: 6px solid transparent; 
  border-top: 6px solid #333; 
}

.tooltip-wrapper {
  display: inline-block;
  position: relative;
}

/* Tooltip Box */
.tooltip-box {
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.tooltip-wrapper:hover .tooltip-box {
  opacity: 1;
  visibility: visible;
}

.tooltip-arrow {
  position: absolute;
  top: -6px; 
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #333;
}

