@media (max-width: 991px) {
  .setting__wrapper {
    .right {
      margin-top: 50px;
      padding: 0;

      .card{
        margin-bottom: 50px;
      }
    }
    .left .functional {
      margin-top: 60px;
    }


  }

  // Career Page
  .career__wrapper {
    .career-form .form-card input.bd-input {
      width: auto;
    }
    .benefits .benefit-card {
      width: auto;
    }

    .resume-btn-row{
      margin-left: 0px;
    }
  }


  // TABLE
    .bd-table{
      tbody{
        tr td p{
          font-size: 16px;
        }
  
        td > div.status{
          padding: 5px 15px;
          font-size: 14px;
        }
      }
  
      thead th > p:first-child {
        margin-left: 12px;
      }
    }
}

@media (max-width: 767px) {
  // Bd Table
  .bd-table{
    width: 550px;

    tbody tr td p {
      font-size: 14px;
    }
    thead th > p {
      font-size: 14px;
    }
  }
}





@media (min-width: 1400px) {
  .career__wrapper{
    .resume-btn-row{
      margin-left: 100px;
    } 
  }
}

@media screen and (max-width: 580px) {

  .bd-table{
    width: 100%;
  }
  .tbl{
    justify-content: center;
    align-items: center;
  }
  .bd-table tbody tr td{
    padding-bottom: 10px !important;
  }
  
}


@media screen and (max-width: 780px) {

  .bd-table tbody td.position{width: auto;}  

.career-table tr, .career-table td{display: block;}
  
.bd-table tbody tr td{
  padding-bottom: 10px !important;
}

}
.card.bg-dark.table-card.overflow-auto{

  padding: 20px;
}