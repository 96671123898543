/*===========================
     05.ABOUT CSS 
===========================*/
.about-area {
    @media #{$xs} {
        margin: 80px 0;
    }
}

.about-left {
    @media #{$xs} {
        padding: 0px 10px;
        margin-bottom: 50px;
    }

    .our-mission {
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 55px;

        @media #{$xs} {
            display: block;
            text-align: center;
        }

        @media #{$sm} {
            display: flex;
            text-align: left;
        }

        .msn-icon {
            min-width: 100px;

            i {
                img {
                    width: 70px;
                }
            }
        }

        .cto {
            min-width: 150px;

            img {
                width: 140px;
            }
        }

        .msn-content {
            @media #{$xs} {
                margin: 25px 0;
            }

            @media #{$sm} {
                margin: 0;
            }

            h5 {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 5px;
            }

            p {
                margin: 0;
                color: #bbb1b1;
                font-size: 16px;
            }
        }
    }

    .cmn-btn {
        @media #{$xs} {
            text-align: center;
        }

        @media #{$sm} {
            text-align: left;
        }

        a {
            color: #E4E4E4;
            font-size: 17px;
            font-weight: 700;
            letter-spacing: 1.2px;
        }
    }
}

.about-right {

    .group-images {
        position: relative;
        margin-top: 85px;
        margin-right: 85px;

        @media #{$xs} {
            margin: 0;
        }

        @media #{$sm} {
            margin-top: 85px;
            margin-right: 85px;
        }

        > img {
            width: 100%;
            border-radius: 5px;
        }

        .about-top {
            position: absolute;
            top: -85px;
            right: -85px;

            @media #{$xs} {
                margin-top: 30px;
                position: static;
            }

            @media #{$sm} {
                margin-top: 0;
                position: absolute;
            }

            img {
                border-radius: 5px;

                @media #{$xs} {
                    width: 100%;
                }

                @media #{$sm} {
                    width: auto;
                }
            }
        }

    }
}

.about-skills {
    background: #FFFFFF;
    border: 1px solid rgba(217, 10, 44, 0.1);
    box-shadow: 0px 0px 50px rgba(53, 51, 83, 0.06);
    border-radius: 10px;
    width: 500px;
    display: flex;
    margin: 0 auto;
    margin-top: -50px;
    position: relative;
    padding: 20px 30px;
    justify-content: space-between;

    @media #{$laptop} {
        padding: 20px;
        width: 425px;
    }

    @media #{$lg} {
        padding: 20px;
        width: 100%;
    }

    @media #{$xs} {
        display: block;
        width: 90%;
    }

    @media #{$sm} {
        display: flex;
        width: 90%;
    }

    .signle-skill {
        display: flex;
        width: 100%;
        align-items: center;

        @media #{$xs} {
            margin-bottom: 10px;

            &.xsm {
                margin-bottom: 0;
            }
        }

        .skill-content {
            h6 {
                font-size: 15px;
                color: #545454;
                margin-bottom: 0px;
                text-transform: capitalize;
            }

            p {
                margin: 0;
                color: #17161A;
            }
        }
    }
}

.signle-skill {
    .progress-bar-circle {
        position: relative;
        height: 70px;
        width: 70px;
        min-width: 80px;
        background-color: transparent;
    }

    .progress-bar-circle div {
        position: absolute;
        height: 70px;
        width: 70px;
        border-radius: 50%;
    }

    .progress-bar-circle div span {
        color: $theme-color;
        position: absolute;
        font-size: 18px;
        font-weight: 700;
        line-height: 60px;
        height: 60px;
        width: 60px;
        left: 50%;
        top: 50%;
        text-align: center;
        border-radius: 50%;
        background-color: white;
        transform: translate(-50%, -50%);
    }

    .progress-bar-circle .background {
        background-color: #fff !important;
    }

    .progress-bar-circle .rotate {
        clip: rect(0 35px 70px 0);
        background-color: $theme-color !important;
    }

    .progress-bar-circle .left {
        clip: rect(0 35px 70px 0);
        opacity: 1;
        background-color: $theme-color !important;
    }

    .progress-bar-circle .right {
        clip: rect(0 35px 70px 0);
        transform: rotate(180deg);
        opacity: 0;
        background-color: $theme-color !important;
    }

}

//Feature counter css
.features-count {
    margin-top: 60px;
    padding: 50px 0;
    background-image: url(../../../public/images/counter-bg.png);
    border-radius: 30px;
    background-size: cover;
    background-position: center center;
    position: relative;
    z-index: 1;

    &:before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #18191D;
        border-radius: 30px;
        z-index: -1;
        opacity: 0.9;
    }
}

.single-count {

    @media #{$xs} {
        margin-bottom: 30px;

        &.xsm {
            margin-bottom: 0;
        }
    }

    i {
        display: block;
        margin-bottom: 15px;

        img {
            height: 70px;
        }
    }

    text-align: center;

    p {
        text-transform: capitalize;
        color: #BDBDBD;
        font-size: 18px;
        font-weight: 500;
        margin: 0;
    }

    .counter {
        span {
            font-size: 35px;
            font-weight: 700;
            color: #fff;
            display: inline-block;
        }

        sup {
            font-size: 35px;
            font-weight: 700;
            color: #fff;
            top: -5px;
            display: inline-block;
        }
    }
}


