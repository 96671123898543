/*===========================
     09.TESTIMONIAL CSS 
===========================*/
.testimonial-area {
    position: relative;
    background-image: url(../../../public/images/testimonial-bg.png);
    background-size: cover;
    background-position: center center;

    &:before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background: #111111;
        z-index: -1;
    }
}

.swiper.testimonial-slider {
    padding: 120px 0;
.swiper-wrapper{
    padding-bottom: 40px;

    margin-bottom: -40px;
}
    .swiper-pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
        bottom: -10px !important;
        left: 0 !important;
        width: auto !important;
        color: #D90A2C !important;
    }

    .swiper-pagination-current {
        font-weight: 600;
        font-size: 37px;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: #ffffff;
    }

    .swiper-pagination-total {
        font-size: 20px;
        color: #D90A2C;
    }

    .swiper-button-next,
    .swiper-button-prev {
        top: initial !important;
        display: flex;
        height: 58px !important;
        width: 80px !important;
        background: #2A2A2A !important;
        box-shadow: 0px 0px 50px rgba(53, 51, 83, 0.06);
        bottom: 0;
        color: #ffffff;
        @include transition(.5s);

        &:hover {
            background-color: #17161A !important;
        }
    }

    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
        right: 10px !important;
        left: auto !important;
        background: #17161A !important;
    }

    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
        left: auto !important;
        right: 90px !important;
        background-color: #fff;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
        font-size: 20px !important;
    }

}

.testimonial-content {
    position: relative;
    text-align: center;

    .quote {
        position: absolute;
        left: 0;

        i {
            font-size: 100px;
            color: #E4E4E4;
            opacity: 0.05;
        }
    }

    p {
        font-weight: normal;
        font-size: 18px;
        text-align: center;
        color: #E4E4E4;
        line-height: 30px;
        max-width: 970px;
        margin: 0 auto;

        @media #{$xs} {
            font-size: 14px;
            line-height: 28px;
        }

        i {

            font-size: 14px;
        }
    }

    .rating {
        margin-top: 30px;

        i {
            color: #FFC107;
            font-size: 20px;
            margin: 0 4px;
        }
    }

    .client-info {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;

        @media #{$xs} {
            margin-bottom: 30px;
        }

        .client-pic {
            min-width: 120px;

            @media #{$xs} {
                min-width: 100px;
            }

            img {
                height: 100px;
                width: 100px;
                border-radius: 50%;

                @media #{$xs} {
                    height: 80px;
                    width: 80px;
                }
            }
        }

        .client-details {
            margin-left: 10px;
            text-align: left;

            h4 {
                font-weight: 600;
                font-size: 25px;
                letter-spacing: 0.03em;
                color: #E4E4E4;
                margin-bottom: 5px;

                @media #{$xs} {
                    font-size: 20px;
                }
            }

            span {
                font-weight: normal;
                font-size: 17px;
                line-height: 30px;
                letter-spacing: 0.03em;
                color: #949494;

                @media #{$xs} {
                    font-size: 16px;
                }
            }
        }

    }
}