/*===========================
     22.FOOTER CSS 
===========================*/
footer {
    position: relative;
    background-image: url(../../../public/images/footer-bg.png);
    background-size: cover;
    background-position: center center;
    z-index: 1;
    padding-top: 50px;
}

.footer-widget {

    @media #{$xs} {
        margin-bottom: 50px;
    }

    .footer-logo {
        margin-bottom: 30px;
        color: white;

        a {
            img {
                width: 100%;
            }
        }
    }

    h4 {
        font-weight: bold;
        font-size: 25px;
        letter-spacing: 0.03em;
        color: #FFFFFF;
        margin-bottom: 15px;

        @media #{$md} {
            font-size: 24px;
        }
    }

    address {
        h4 {
            display: inline-block;
            font-weight: 500;
            font-size: 25px;
            letter-spacing: 0.03em;
            color: #FFFFFF;
            margin-bottom: 15px;
            position: relative;

            &:before {
                position: absolute;
                content: "";
                width: 100%;
                border: 1.5px solid #D90A2C;
                left: 0;
                bottom: -2px;
            }
        }

        p {
            font-weight: 500;
            color: #FFFFFF;
            font-size: 15px;
            @media #{$md} {
                font-size: 14px;
            }
        }
    }
}

.social-media-icons {
    list-style: none;

    li {
        display: inline-block;
        padding-right: 30px;

        @media #{$laptop} {
            padding-right: 25px;
        }

        @media #{$lg} {
            padding-right: 15px;
        }

        @media #{$md} {
            padding-right: 5px;
        }

        a {
            display: inline-block;
            border: 1px solid #28292B;
            box-sizing: border-box;
            border-radius: 10px;
            height: 35px;
            width: 35px;
            line-height: 35px;
            text-align: center;
            color: #fff;
            @include transition(.8s);

            @media #{$md} {
                height: 30px;
                width: 30px;
                line-height: 30px;
            }

            &:hover {
                background-color: #D90A2C;
                @include transform (rotate(360deg));
            }
        }
    }
}

.footer-menu {
    list-style: none;

    li {
        display: block;
        padding-bottom: 15px;

        &:last-child {
            padding-bottom: 0;
        }

        a {
            position: relative;
            font-weight: 500;
            font-size: 15px;
            color: #FFFFFF;
            display: inline-block;
            @include transition(.5s);

            @media #{$md} {
                font-size: 15px;
            }

            &:before {
                position: absolute;
                content: "";
                top: 50%;
                @include transform(translateY(-50%));
                left: -20px;
                height: 10px;
                width: 10px;
                background-color: #D90A2C;
                border-radius: 50%;
                visibility: hidden;
                opacity: 0;
                @include transition(.5s);
            }

            &:hover {
                color: $theme-color;
                padding-left: 15px;
            }

            &:hover:before {
                left: 0;
                visibility: visible;
                opacity: 1;

            }
        }


    }

}

.number,
.office-mail,
.address {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;

    p {
        font-weight: 500;
        font-size: 15px;
        letter-spacing: 0.03em;
        color: #FFFFFF;
        margin: 0;

        @media #{$md} {
            font-size: 14px;
        }
    }
}

.phone,
.email {
    a {
        font-weight: 500;
        font-size: 15px;
        letter-spacing: 0.03em;
        color: #FFFFFF;
        display: block;
        padding-bottom: 5px;
        @include transition(.5s);

        @media #{$md} {
            font-size: 13px;
        }

        &:hover {
            color: $theme-color;
        }
    }
}

.num-icon,
.mail-icon,
.address-icon {
    min-width: 35px;

    @media #{$md} {
        min-width: 30px;
    }

    i {
        color: $theme-color;
        font-size: 20px;

        @media #{$md} {
            font-size: 20px;
        }
    }
}

.footer-bottom {
    margin-top: 30px;
    padding: 30px 0;
    border-top: 2px solid #292929;

    @media #{$xs} {
        margin-top: 30px !important;
    }

    .copy-txt {
        @media #{$xs} {
            text-align: center;
        }

        span {
            font-size: 14px;
            color: #FFFFFF;

            @media #{$lg} {
                font-size: 13px;
            }

            @media #{$xs} {
                font-size: 13px;
            }

            a {
                font-weight: 700;
                color: #fff;
                @include transition(.5s);

                &:hover {
                    color: $theme-color;
                }
            }
        }
    }

    .footer-bottom-menu {
        list-style: none;
        text-align: right;

        @media #{$xs} {
            margin-top: 30px;
            text-align: center;
        }

        li {
            display: inline-block;

            a {
                position: relative;
                font-size: 14px;
                text-align: right;
                letter-spacing: 0.01em;
                color: #FFFFFF;
                padding: 0 20px;
                line-height: 1;
                @include transition(.5s);

                @media #{$xs} {
                    padding: 0;
                    padding-right: 10px;
                }

                @media #{$sm} {
                    padding: 0 20px;
                }

                &:before {
                    border-right: 1px solid #fff;
                    position: absolute;
                    content: "";
                    height: 60%;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);

                    @media #{$xs} {
                        display: none;
                    }

                    @media #{$sm} {
                        display: block;
                    }
                }

                &:hover {
                    color: $theme-color;
                }
            }

            &:last-child a:before {
                display: none;
            }
        }
    }
}