@import url(./assets/css/bootstrap.min.css);
@import url(./assets/css/bootstrap-icons.css);
@import url(./assets/css/all.min.css);
@import url(./assets/css/fontawesome.min.css);
@import url(./assets/css/swiper-bundle.min.css);
@import url(./assets/css/nice-select.css);
@import url(./assets/css/jQuery-plugin-progressbar.css);
@import url(./assets/css/barfiller.css);
@import url(./assets/sass/style.scss);

.CircularProgressbar {
  .CircularProgressbar-trail {
    stroke: #000;
    stroke-linecap: round;
  }
  .CircularProgressbar-path {
    stroke: #d90a2c;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
  }
  .CircularProgressbar-text {
    fill: #d90a2c;
    font-size: 22px;
    font-weight: 700;
  }
}
.single-portfolio.masonary.masonary-top-72 {
  margin-top: -72px;
}
.single-portfolio.masonary.massonary-top-30 {
  margin-top: -30px;
}
.single-portfolio.masonary.masonary-top-113 {
  margin-top: -113px;
}

.progressbar-label {
  color: #fff;
  padding-left: 10px;
}
.out-story .CircularProgressbar {
  .CircularProgressbar-path {
    stroke: #d6d6d6;
  }
  .CircularProgressbar-text {
    fill: #ffff;
    font-size: 18px;
    font-weight: 600;
    transform: translateY(-22px);
  }
}
.wrapper {
  margin: auto;
  width: 60%;
  text-align: center;
  line-height: 2em;
}

.hover .cursor-dot {
  width: 100px;
  height: 100px;
  z-index: -1;
  background-color: lightblue;
}

.hover .cursor-border {
  z-index: -1;
}

.link {
  padding: 1em;
}

.react-hover .cursor-border {
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 0;
  z-index: -1;
}

.react-hover .cursor-dot {
  z-index: -3;
}

.author {
  color: orange;
  font-weight: 800;
  font-size: 1.3em;
}

.author-on-hover .cursor-dot {
  width: 100px;
  height: 100px;
  background-color: #fff;
  z-index: -1;
}

.author-on-hover .cursor-border {
  z-index: -1;
}

.head {
  color: bisque;
}

.head-hover .cursor-border {
  border: 5px dotted darkorange;
}

.head-hover .cursor-dot {
  background-color: purple;
  width: 20px;
  height: 20px;
}

.children {
  color: rgb(114, 253, 0);
}

.children-hover .cursor-dot {
  width: 600px;
  height: 600px;
  background-color: white;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-menu li a {
  cursor: pointer !important;
}
.portfolio-hover a img {
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  padding: 5px;
}
.SRLThumbnailsContainer.css-13uajvl {
  display: none !important;
}
.scroll-top.opacity {
  color: #d90a2c;
  font-weight: 800;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  background: #333;
  z-index: 99999;
  width: 100vw;
  min-height: 80px;
  height: 100vh;
}
.dot1 {
  width: 15px;
  height: 15px;
  background: #d90a2c;
  animation: ScaleInOut 0.6s ease-in-out 0.2s infinite alternate;
}

.dot2 {
  width: 15px;
  height: 15px;
  background: #d90a2c;
  animation: ScaleInOut 0.6s ease-in-out 0.4s infinite alternate;
}

.dot3 {
  width: 15px;
  height: 15px;
  background: #d90a2c;
  animation: ScaleInOut 0.6s ease-in-out 0.6s infinite alternate;
}
@keyframes ScaleInOut {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.5);
  }
}

.height300 {
  height: 300px;
}

.project-area .swiper.portfolio-slider.project-slider {
  position: relative !important;
}

.loader2 {
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  z-index: 99999;
  width: 100vw;
  min-height: 120px;
  height: 100%;
  border-radius: 20px;
  .dot1 {
    width: 15px;
    height: 15px;
    background: #d90a2c;
    animation: ScaleInOut 0.6s ease-in-out 0.2s infinite alternate;
  }

  .dot2 {
    width: 15px;
    height: 15px;
    background: #d90a2c;
    animation: ScaleInOut 0.6s ease-in-out 0.4s infinite alternate;
  }

  .dot3 {
    width: 15px;
    height: 15px;
    background: #d90a2c;
    animation: ScaleInOut 0.6s ease-in-out 0.6s infinite alternate;
  }
}

@keyframes ScaleInOut {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.5);
  }
}
.table-link,.btn-link{
  cursor: pointer;
}

.open {
  display: block;
}
.toggle-button {
  display: none;
}
.arrowbtn {
  display: none;
}
@media (max-width: 992px) {
  .toggle-button {
    display: block;
    position: fixed;
    left: -5px;
    top: 142px;
    background-color: #730000;
    z-index: 999;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    opacity: 1;
    transition: transform 0.3s ease; 
  }
  .toggle-button2 {
    display: block;
    position: fixed;
    left: 270px;
    top: 142px;
    background-color: #730000;
    z-index: 9;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    transition: transform 0.3s ease; 
}
  .arrowbtn {
    display: block;
    color: white;
    rotate: 180deg;
    position: absolute;
    top: 2px;
    left: 4px;
    font-size: x-large;
  }
  .arrowbtnrotate {
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    color: white;
    font-size: x-large;
  }
  .open {
    position: fixed;
    height: 100%;
    z-index: 99;
    opacity: 1;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  .unopen {
    position: absolute;
    left: -400px;
    transition: 0.3s ease;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease; 
  }
}
